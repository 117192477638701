export enum Tr {
  appTitle = 'appTitle',
  logout = 'logout',
  active = 'active',
  deactivated = 'deactivated',
  pending = 'pending',
  rejected = 'rejected',
  salesDocumentsTab = 'salesDocumentsTab',
  purchaseDocumentsTab = 'purchaseDocumentsTab',
  forWho = 'forWho',
  eArchiveManager = 'eArchiveManager',
  goBackToEarchive = 'goBackToEarchive',
  goBackToAdministrationList = 'goBackToAdministrationList',
  tenantsList = 'tenantsList',
  usersList = 'usersList',
  open = 'open',
  searchCompany = 'searchCompany',
  companiesList = 'companiesList',
  settings = 'settings',
  authentication = 'authentication',
  earchiveTenants = 'earchiveTenants',
  getCurrentPermissionsFromKsef = 'getCurrentPermissionsFromKsef',
  pendingSendToKsef = 'pendingSendToKsef',
  ksefConnectionErrorMessage = 'ksefConnectionErrorMessage',
  sendToKsefSendingError = 'sendToKsefSendingError',
  genericError = 'genericError',
  submissionInProgress = 'submissionInProgress',
  submissionSent = 'submissionSent',
  createTenantForm_Header = 'createTenantForm_Header',
  createTenantForm_Description = 'createTenantForm_Description',
  createTenantForm_CloudConsent = 'createTenantForm_CloudConsent',
  createTenantForm_PersonalDataConsent = 'createTenantForm_PersonalDataConsent',
  createTenantForm_PrivacyPolicyConsent = 'createTenantForm_PrivacyPolicyConsent',
  createTenantForm_CompanyRepresentationConsent = 'createTenantForm_CompanyRepresentationConsent',
  createTenantForm_MarketingEndDeviceConsent = 'createTenantForm_MarketingEndDeviceConsent',
  createTenantForm_MarketingEmailConsent = 'createTenantForm_MarketingEmailConsent',
  createTenantForm_PersonalDataForPartnersConsent = 'createTenantForm_PersonalDataForPartnersConsent',
  createTenantForm_Summary = 'createTenantForm_Summary',
  createTenantForm_Submit = 'createTenantForm_Submit',
  createTenantForm_RequiredConsentsHandler = 'createTenantForm_RequiredConsentsHandler',
  addCompanyButton = 'addCompanyButton',
  addTenantButton = 'addTenantButton',
  saveCompanyButton = 'saveCompanyButton',
  addUserButton = 'addUserButton',
  saveUserButton = 'saveUserButton',
  actions = 'actions',
  yes = 'yes',
  no = 'no',
  save = 'save',
  continue = 'continue',
  edit = 'edit',
  password = 'password',
  acceptTenant = 'acceptTenant',
  discardTenant = 'discardTenant',
  cancelButton = 'cancelButton',
  cancelChangesButton = 'cancelChangesButton',
  dictionary = 'dictionary',
  saveTenantButton = 'saveTenantButton',
  activateTenantButton = 'activateTenantButton',
  deactivateTenantButton = 'deactivateTenantButton',
  removeButton = 'removeButton',
  admin = 'admin',
  editCompanyForm_ContributorCheckbox = 'editCompanyForm_ContributorCheckbox',
  nipTooltip_Content = 'nipTooltip_Content',
  peselTooltip_Content = 'peselTooltip_Content',
  tenantCreateTooltip_Header = 'tenantCreateTooltip_Header',
  tenantCreateTooltip_Content = 'tenantCreateTooltip_Content',
  subscriptionNumberTooltip_Header = 'subscriptionNumberTooltip_Header',
  subscriptionNumberTooltip_Content = 'subscriptionNumberTooltip_Content',
  phoneNumberTooltip_Header = 'phoneNumberTooltip_Header',
  phoneNumberTooltip_Content = 'phoneNumberTooltip_Content',
  companyKeyTooltip_Content = 'companyKeyTooltip_Content',
  companyKeyTooltip_Header = 'companyKeyTooltip_Header',
  tenantKeyTooltip_Content = 'tenantKeyTooltip_Content',
  tenantKeyTooltip_Header = 'tenantKeyTooltip_Header',
  ksefActionTooltip_Content = 'ksefActionTooltip_Content',
  ksefDownloadTooltip_Header = 'ksefDownloadTooltip_Header',
  ksefDownloadTooltip_Content = 'ksefDownloadTooltip_Content',
  noInvoiceReadCredentialKsefDownloadTooltip_Header = 'noInvoiceReadCredentialKsefDownloadTooltip_Header',
  noInvoiceReadCredentialKsefDownloadTooltip_Content = 'noInvoiceReadCredentialKsefDownloadTooltip_Content',
  noInvoiceWriteKsefSendTooltip_Header = 'noInvoiceWriteKsefSendTooltip_Header',
  noInvoiceWriteKsefSendTooltip_Content = 'noInvoiceWriteKsefSendTooltip_Content',
  invoiceSentToKsefDisabledCauseSchemaTooltip = 'invoiceSentToKsefDisabledCauseSchemaTooltip',
  notificationInvoicesNotFetchedTooltip_Content = 'notificationInvoicesNotFetchedTooltip_Content',
  permissionRegisterButtonTooltip_Content = 'permissionRegisterButtonTooltip_Content',
  permissionRegisterButtonTooltip_Header = 'permissionRegisterButtonTooltip_Header',
  companyName = 'companyName',
  companyKey = 'companyKey',
  registrationDate = 'registrationDate',
  status = 'status',
  rejectedReason = 'rejectedReason',
  decisionDate = 'decisionDate',
  tenantName = 'tenantName',
  subscriptionNumber = 'subscriptionNumber',
  subscription = 'subscription',
  tenantKey = 'tenantKey',
  NIP = 'NIP',
  PESEL = 'PESEL',
  deleteCompany = 'deleteCompany',
  backButton = 'backButton',
  userEmail = 'userEmail',
  userFirstName = 'userFirstName',
  userLastName = 'userLastName',
  userPhoneNumber = 'userPhoneNumber',
  userContactPhoneNumber = 'userContactPhoneNumber',
  organizationName = 'organizationName',
  comment = 'comment',
  company = 'company',
  addCompany = 'addCompany',
  deleteUser = 'deleteUser',
  usersTotal = 'usersTotal',
  companiesAssigned = 'companiesAssigned',
  Contributor = 'Contributor',
  roleAssignment = 'roleAssignment',
  selectCompany = 'selectCompany',
  validation = 'validation',
  invoiceIsDownloaded = 'invoiceIsDownloaded',
  invoiceOwnNumber = 'invoiceOwnNumber',
  invoiceName = 'invoiceName',
  invoiceDocumentType = 'invoiceDocumentType',
  invoiceSender = 'invoiceSender',
  invoicesenderNIP = 'invoicesenderNIP',
  invoiceReceiver = 'invoiceReceiver',
  invoiceReceiverNIP = 'invoiceReceiverNIP',
  invoiceAmountVat = 'invoiceAmountVat',
  invoiceAmountGross = 'invoiceAmountGross',
  invoiceAmountNet = 'invoiceAmountNet',
  invoiceCurrency = 'invoiceCurrency',
  invoiceKsefIssueDate = 'invoiceKsefIssueDate',
  invoiceKsefIssueDateFrom = 'invoiceKsefIssueDateFrom',
  invoiceKsefIssueDateTo = 'invoiceKsefIssueDateTo',
  invoiceKsefDocumentNumber = 'invoiceKsefDocumentNumber',
  invoiceIssueDate = 'invoiceIssueDate',
  invoiceKsefStatus = 'invoiceKsefStatus',
  postingStatus = 'postingStatus',
  postingUnsavedChangesDialogTitle = 'postingUnsavedChangesDialogTitle',
  postingUnsavedChangesDialog = 'postingUnsavedChangesDialog',
  purchaseDocuments = 'purchaseDocuments',
  salesDocuments = 'salesDocuments',
  newCompany = 'newCompany',
  companyDetails = 'companyDetails',
  newUser = 'newUser',
  ok = 'ok',
  operationSuccess = 'operationSuccess',
  cannotDeleteCompanyDialogTitle = 'cannotDeleteCompanyDialogTitle',
  cannotDeleteCompanyDialog = 'cannotDeleteCompanyDialog',
  captchaErrorDialogTitle = 'captchaErrorDialogTitle',
  captchaErrorDialog = 'captchaErrorDialog',
  tryAgain = 'tryAgain',
  generalDetails = 'generalDetails',
  userDetails = 'userDetails',
  tenantDetails = 'tenantDetails',
  rejectTenantProposal = 'rejectTenantProposal',
  rejectTenantProposalComment = 'rejectTenantProposalComment',
  commentForUser = 'commentForUser',
  authorization = 'authorization',
  authorizationStatus = 'authorizationStatus',
  companyManagement = 'companyManagement',
  authorizationKSeF = 'authorizationKSeF',
  authorizationKSeFDesc = 'authorizationKSeFDesc',
  disconnectKSeF = 'disconnectKSeF',
  disconnectKSeFDesc = 'disconnectKSeFDesc',
  disconnectKSeFTokenDesc = 'disconnectKSeFTokenDesc',
  authorizedKSeFDesc = 'authorizedKSeFDesc',
  authorizedKSeFPermissionPageDesc = 'authorizedKSeFPermissionPageDesc',
  profilZaufanyHeader = 'profilZaufanyHeader',
  profilZaufanyContent = 'profilZaufanyContent',
  authByTokenHeader = 'authByTokenHeader',
  authByTokenContent = 'authByTokenContent',
  writeToken = 'writeToken',
  token = 'token',
  szafirHeader = 'szafirHeader',
  szafirContent = 'szafirContent',
  szafirErrorAuthentication = 'szafirErrorAuthentication',
  szafirErrorUnexpected = 'szafirErrorUnexpected',
  szafirErrorNoExtension = 'szafirErrorNoExtension',
  szafirErrorNoHostApp = 'szafirErrorNoHostApp',
  szafirErrorHostAppOutdated = 'szafirErrorHostAppOutdated',
  accessForbiddenTitle = 'accessForbiddenTitle',
  accessForbiddenContent = 'accessForbiddenContent',
  getUpoPdf = 'getUpoPdf',
  getUpoXml = 'getUpoXml',
  getUPOActionTooltip = 'getUPOActionTooltip',
  downloadInvoicePdf = 'downloadInvoicePdf',
  downloadPdf = 'downloadPdf',
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
  getInvoiceXml = 'getInvoiceXml',
  downloadInvoice = 'downloadInvoice',
  sendToKSeF = 'sendToKSeF',
  postInvoice = 'postInvoice',
  exportToSymfoniaFinancesAndAccountancy = 'exportToSymfoniaFinancesAndAccountancy',
  exportToSymfoniaTrade = 'exportToSymfoniaTrade',
  exportToSymfoniaTrade_Tooltip = 'exportToSymfoniaTrade_Tooltip',
  newPermission = 'newPermission',
  newPermissions = 'newPermissions',
  downloadFromKsef = 'downloadFromKsef',
  downloadAllFromKsef = 'downloadAllFromKsef',
  downloadFromKsefByDates = 'downloadFromKsefByDates',
  editPermission = 'editPermission',
  permissions = 'permissions',
  operation = 'operation',
  dateOfOperation = 'dateOfOperation',
  permissionsName = 'permissionsName',
  PermissionsFor = 'PermissionsFor',
  PermissionFor = 'PermissionFor',
  AddPermissions = 'AddPermissions',
  AddPermission = 'AddPermission',
  CurrentKSeFPermissions = 'CurrentKSeFPermissions',
  RegisteredPermissionsSymfonyKSeF = 'RegisteredPermissionsSymfonyKSeF',
  permissionNameHeader = 'permissionNameHeader',
  permissionNameContent = 'permissionNameContent',
  deletePermissionTitle = 'deletePermissionTitle',
  deletePermissionContent = 'deletePermissionContent',
  deletePermissionTableActionContent = 'deletePermissionTableActionContent',
  addPermissionError = 'addPermissionError',
  addPermissionSuccess = 'addPermissionSuccess',
  deletePermissionSuccess = 'deletePermissionSuccess',
  editPermissionSuccess = 'editPermissionSuccess',
  CredentialsInvoiceRead = 'CredentialsInvoiceRead',
  CredentialsInvoiceWrite = 'CredentialsInvoiceWrite',
  CredentialsManage = 'CredentialsManage',
  CredentialsRead = 'CredentialsRead',
  CredentialsSelfInvoicing = 'CredentialsSelfInvoicing',
  getUpoError = 'getUpoError',
  actionError = 'actionError',
  postingInvoicesActionError = 'postingInvoicesActionError',
  getUpoMissingError = 'getUpoMissingError',
  grantKSeFPermissionSubInProgress = 'grantKSeFPermissionSubInProgress',
  getInvoiceXmlError = 'getInvoiceXmlError',
  getInvoicesFromKSeFSuccess = 'getInvoicesFromKSeFSuccess',
  getInvoicesFromKSeFPartialSuccess = 'getInvoicesFromKSeFPartialSuccess',
  getInvoicesFromKSeFCancelled = 'getInvoicesFromKSeFCancelled',
  getInvoicesFromKSeFWithDateSuccess = 'getInvoicesFromKSeFWithDateSuccess',
  getInvoicesFromKSeFError = 'getInvoicesFromKSeFError',
  getInvoicesFromKSeFNoPackage = 'getInvoicesFromKSeFNoPackage',
  getInvoicesFromKSeFNoPackageTitle = 'getInvoicesFromKSeFNoPackageTitle',
  getInvoicesFromKSeFUpToDate = 'getInvoicesFromKSeFUpToDate',
  getInvoicesFromKSeFNoData = 'getInvoicesFromKSeFNoData',
  sendManyToKSeFInProgress = 'sendManyToKSeFInProgress',
  sendToKSeFInProgress = 'sendToKSeFInProgress',
  sendManyToKSeFSuccess = 'sendManyToKSeFSuccess',
  sendToKSeFSuccess = 'sendToKSeFSuccess',
  autoSendToKSeFInProgress = 'autoSendToKSeFInProgress',
  autoSendToKSeFSuccess = 'autoSendToKSeFSuccess',
  autoSendToKSeFError = 'autoSendToKSeFError',
  sendToKSeFError = 'sendToKSeFError',
  sendManyToKSeFError = 'sendManyToKSeFError',
  noPermissionToWrite = 'noPermissionToWrite',
  disconnectWithKSeF = 'disconnectWithKSeF',
  rejectDisconnectWithKSeF = 'rejectDisconnectWithKSeF',
  disconnectWithKSeFTitle = 'disconnectWithKSeFTitle',
  disconnectWithKSeFContent = 'disconnectWithKSeFContent',
  disconnectWithKSeF_error = 'disconnectWithKSeF_error',
  disconnectWithKSeF_success = 'disconnectWithKSeF_success',
  tenantAdminInfo = 'tenantAdminInfo',
  bulkActions = 'bulkActions',
  missingUpoTitle = 'missingUpoTitle',
  missingUpoContent = 'missingUpoContent',
  privacyPolicy = 'privacyPolicy',
  regulations = 'regulations',
  help = 'help',
  contact = 'contact',
  copyrightSymfonia = 'copyrightSymfonia',
  changePassword = 'changePassword',
  connect = 'connect',
  errorAuthorizationWithToken = 'errorAuthorizationWithToken',
  companyNotFoundTitle = 'companyNotFoundTitle',
  companyNotFoundDesc = 'companyNotFoundDesc',
  goBackToCompaniesList = 'goBackToCompaniesList',
  userNotFoundTitle = 'userNotFoundTitle',
  userNotFoundDesc = 'userNotFoundDesc',
  goBackToUsersList = 'goBackToUsersList',
  eArchiveNoAccessTitle = 'eArchiveNoAccessTitle',
  eArchiveNoAccessDesc = 'eArchiveNoAccessDesc',
  companyInactiveTitle = 'companyInactiveTitle',
  companyInactiveDesc = 'companyInactiveDesc',
  tenantNotFoundTitle = 'tenantNotFoundTitle',
  tenantNotFoundDesc = 'tenantNotFoundDesc',
  goBackToTenantsList = 'goBackToTenantsList',
  bokErrorTitle = 'bokErrorTitle',
  bokErrorDesc = 'bokErrorDesc',
  myEnvironmentsErrorTitle = 'myEnvironmentsErrorTitle',
  myEnvironmentsErrorDesc = 'myEnvironmentsErrorDesc',
  inactiveTenantTitle = 'inactiveTenantTitle',
  inactiveTenantDesc = 'inactiveTenantDesc',
  tenantRejectedTitle = 'tenantRejectedTitle',
  tenantRejectedDesc = 'tenantRejectedDesc',
  allDocuments = 'allDocuments',
  toDownload = 'toDownload',
  downloaded = 'downloaded',
  toSend = 'toSend',
  sent = 'sent',
  gridErrorMessage = 'gridErrorMessage',
  showDocumentsList = 'showDocumentsList',
  documentsList = 'documentsList',
  missingInvoiceReason_PROCESSING = 'missingInvoiceReason_PROCESSING',
  missingInvoiceReason_ERROR = 'missingInvoiceReason_ERROR',
  missingInvoiceReason_NOT_SENT = 'missingInvoiceReason_NOT_SENT',
  missingInvoiceReason_NOT_EXISTS = 'missingInvoiceReason_NOT_EXISTS',
  missingInvoiceReason_UPO_NOT_GENERATED = 'missingInvoiceReason_UPO_NOT_GENERATED',
  missingInvoiceReason_VALIDATION_ERROR = 'missingInvoiceReason_VALIDATION_ERROR',
  missingInvoiceReason_INTERRUPTED_SENDING = 'missingInvoiceReason_INTERRUPTED_SENDING',
  missingInvoiceReason_INTERRUPTED_PENDING = 'missingInvoiceReason_INTERRUPTED_PENDING',
  missingInvoiceReason_OCR = 'missingInvoiceReason_OCR',
  missingInvoiceReason_SCHEMA_ERROR = 'missingInvoiceReason_SCHEMA_ERROR',
  missingInvoiceReason_NO_SESSION_REFERENCE_NUMBER = 'missingInvoiceReason_NO_SESSION_REFERENCE_NUMBER',
  createTenantTitle = 'createTenantTitle',
  saveChanges = 'saveChanges',
  registrationTenant = 'registrationTenant',
  registrationTenantReject = 'registrationTenantReject',
  authImpossibleWithoutNip = 'authImpossibleWithoutNip',
  authImpossibleWithoutNipTEXT = 'authImpossibleWithoutNipTEXT',
  azureWafPolicyError = 'azureWafPolicyError',
  forceEditPermissionTitle = 'forceEditPermissionTitle',
  forceEditPermissionContent = 'forceEditPermissionContent',
  forceEditButton = 'forceEditButton',
  terminateKSeFSession = 'terminateKSeFSession',
  terminateKSeFSessionInProgress = 'terminateKSeFSessionInProgress',
  terminateKSeFSessionSuccess = 'terminateKSeFSessionSuccess',
  terminateKSeFSessionError = 'terminateKSeFSessionError',
  sessionKSef = 'sessionKSef',
  sessionStatusTooltip_Header = 'sessionStatusTooltip_Header',
  sessionStatusTooltip_Content = 'sessionStatusTooltip_Content',
  companyExist_Header = 'companyExist_Header',
  companyExist_Content = 'companyExist_Content',
  continueButton = 'continueButton',
  getInvoicesFromKSeFStarted = 'getInvoicesFromKSeFStarted',
  companyPendingTitle = 'companyPendingTitle',
  companyPendingeDesc = 'companyPendingeDesc',
  notifications = 'notifications',
  emailNotification = 'emailNotification',
  emailNotificationContent = 'emailNotificationContent',
  AUTHORIZATION_OF_COMPANY_SUCCEEDED = 'AUTHORIZATION_OF_COMPANY_SUCCEEDED',
  DOWNLOAD_INVOICES_FROM_K_SE_F = 'DOWNLOAD_INVOICES_FROM_K_SE_F',
  SEND_INVOICES_TO_K_SE_F = 'SEND_INVOICES_TO_K_SE_F',
  DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED = 'DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED',
  AUTO_SEND_INVOICES_TO_K_SE_F_SUCCEEDED = 'AUTO_SEND_INVOICES_TO_K_SE_F_SUCCEEDED',
  AUTO_DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED = 'AUTO_DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED',
  USER_UNAUTHORIZED_IN_KSEF = 'USER_UNAUTHORIZED_IN_KSEF',
  DOWNLOAD_UPO_SUCCEEDED = 'DOWNLOAD_UPO_SUCCEEDED',
  PERMISSIONS_EDITED = 'PERMISSIONS_EDITED',
  INVOICES_NOT_FETCHED = 'INVOICES_NOT_FETCHED',
  SEND_INVOICES_TO_K_SE_F_SUCCEEDED = 'SEND_INVOICES_TO_K_SE_F_SUCCEEDED',
  COMPANY_CREATED_IN_TENANT = 'COMPANY_CREATED_IN_TENANT',
  COMPANY_DELETED = 'COMPANY_DELETED',
  COMPANY_INFO_EDITED = 'COMPANY_INFO_EDITED',
  TENANT_DEACTIVATED = 'TENANT_DEACTIVATED',
  TENANT_INFO_EDITED = 'TENANT_INFO_EDITED',
  USER_ASSIGNED_TO_COMPANY = 'USER_ASSIGNED_TO_COMPANY',
  COMPANY_CREATED_IN_ORGANIZATION = 'COMPANY_CREATED_IN_ORGANIZATION',
  ORGANIZATION_INFO_EDITED = 'ORGANIZATION_INFO_EDITED',
  emailNotificationError = 'emailNotificationError',
  modulSwitchAdmin = 'modulSwitchAdmin',
  modulSwitchEarchive = 'modulSwitchEarchive',
  switchTenantPermissionTitle = 'switchTenantPermissionTitle',
  switchTenantPermissionContent = 'switchTenantPermissionContent',
  switchCompanyPermissionTitle = 'switchCompanyPermissionTitle',
  switchCompanyPermissionContent = 'switchCompanyPermissionContent',
  switchTenantContinueButton = 'switchTenantContinueButton',
  switchTenantCancelButton = 'switchTenantCancelButton',
  backgroundInvoiceDownload = 'backgroundInvoiceDownload',
  downloadSwitchOff = 'downloadSwitchOff',
  downloadSwitchOnInterval = 'downloadSwitchOnInterval',
  downloadSwitchOn = 'downloadSwitchOn',
  sendSwitchOn = 'sendSwitchOn',
  sendSwitchInterval = 'sendSwitchInterval',
  switchStateInfo = 'switchStateInfo',
  sharedAccessSwitchLabel = 'sharedAccessSwitchLabel',
  sharedAccessChangeError = 'sharedAccessChangeError',
  sharedAccessInfo = 'sharedAccessInfo',
  sharedAccessActivationInfo = 'sharedAccessActivationInfo',
  notAuthorizedAutoInvoiceError = 'notAuthorizedAutoInvoiceError',
  autoFetchInvoiceTooltip_Content = 'autoFetchInvoiceTooltip_Content',
  autoFetchInvoiceTooltip_Header = 'autoFetchInvoiceTooltip_Header',
  tenant = 'tenant',
  checkAll = 'checkAll',
  adminInformation = 'adminInformation',
  assignAllAdminsOnCompanyCreatedInfo = 'assignAllAdminsOnCompanyCreatedInfo',
  serverTypeQuestion = 'serverTypeQuestion',
  serverTypeAnnotation = 'serverTypeAnnotation',
  tooltipProd_Content = 'tooltipProd_Content',
  tooltipProd_Header = 'tooltipProd_Header',
  tooltipDemo_Content = 'tooltipDemo_Content',
  tooltipDemo_Header = 'tooltipDemo_Header',
  serverDEMO = 'serverDEMO',
  serverPROD = 'serverPROD',
  typeOfConnection = 'typeOfConnection',
  DEMO = 'DEMO',
  PROD = 'PROD',
  connectionWithServer = 'connectionWithServer',
  salesInvoices = 'salesInvoices',
  purchaseInvoices = 'purchaseInvoices',
  salesInvoices_Tooltip = 'salesInvoices_Tooltip',
  purchaseInvoices_Tooltip = 'purchaseInvoices_Tooltip',
  redirectToHub = 'redirectToHub',
  autoPosting = 'autoPosting',
  contractor = 'contractor',
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  invoiceBound = 'invoiceBound',
  details = 'details',
  postingStatus_no_action = 'postingStatus_no_action',
  postingStatus_posted = 'postingStatus_posted',
  postingStatus_decree_sentFilter = 'postingStatus_decree_sentFilter',
  postingStatus_decree_sent = 'postingStatus_decree_sent',
  postingStatus_decree_downloaded = 'postingStatus_decree_downloaded',
  postingStatus_notAllowed = 'postingStatus_notAllowed',
  postingStatus_posting = 'postingStatus_posting',
  postingStatus_postingFailed = 'postingStatus_postingFailed',
  postingStatus_postingIncomplete = 'postingStatus_postingIncomplete',
  postingStatus_readyForPosting = 'postingStatus_readyForPosting',
  postingStatus_undefined = 'postingStatus_undefined',
  postingStatus_incomplete = 'postingStatus_incomplete',
  postringConfiguration_no_assignment = 'postringConfiguration_no_assignment',
  sendToFK = 'sendToFK',
  sendAndSave = 'sendAndSave',
  organizationCreated = 'organizationCreated',
  organizationAccepted = 'organizationAccepted',
  organizationRejected = 'organizationRejected',
  organizationDeactivated = 'organizationDeactivated',
  organizationDeleted = 'organizationDeleted',
  dateFrom = 'dateFrom',
  date = 'date',
  to = 'to',
  toLabel = 'toLabel',
  from = 'from',
  selectSpecificDate = 'selectSpecificDate',
  selectDate = 'selectDate',
  multiSelectPlaceHolder = 'multiSelectPlaceHolder',
  filterLabel = 'filterLabel',
  tableLabel = 'tableLabel',
  closeLabel = 'closeLabel',
  clearAllLabel = 'clearAllLabel',
  clearFiltersLabel = 'clearFiltersLabel',
  result = 'result',
  Foreign = 'Foreign',
  Own = 'Own',
  Downloaded = 'Downloaded',
  ToDownload = 'ToDownload',
  Sent = 'Sent',
  ToSend = 'ToSend',
  InvoiceNumber = 'InvoiceNumber',
  DocumentTypes = 'DocumentTypes',
  IssuerNames = 'IssuerNames',
  IssuerNip = 'IssuerNip',
  RecipientNames = 'RecipientNames',
  RecipientNip = 'RecipientNip',
  KsefNumber = 'KsefNumber',
  KsefNumbers = 'KsefNumbers',
  DocumentNumber = 'DocumentNumber',
  KSeFStatuses = 'KSeFStatuses',
  NetValue = 'NetValue',
  VatValue = 'VatValue',
  GrossValue = 'GrossValue',
  SearchSpecificNumber = 'SearchSpecificNumber',
  DateOfIssue = 'DateOfIssue',
  category = 'category',
  account = 'account',
  wn = 'wn',
  ma = 'ma',
  positions = 'positions',
  decreeNotFound = 'decreeNotFound',
  decreeFailed = 'decreeFailed',
  decreeTableFailed = 'decreeTableFailed',
  reloadPage = 'reloadPage',
  goBack = 'goBack',
  decreeEditFailed = 'decreeEditFailed',
  decreeSaveFailed = 'decreeSaveFailed',
  decreeSaveAndRefreshFailed = 'decreeSaveAndRefreshFailed',
  operationFailed = 'operationFailed',
  posting = 'posting',
  postingConfiguration = 'postingConfiguration',
  postingNotActive = 'postingNotActive',
  postingNotAvailable = 'postingNotAvailable',
  adminContact = 'adminContact',
  postingActivation = 'postingActivation',
  activatingProcess = 'activatingProcess',
  repeatActivation = 'repeatActivation',
  postingActive = 'postingActive',
  allCategories = 'allCategories',
  basicCategories = 'basicCategories',
  ownCategories = 'ownCategories',
  categoryType = 'categoryType',
  accountKUP = 'accountKUP',
  accountNKUP = 'accountNKUP',
  settlements = 'settlements',
  addCategory = 'addCategory',
  categoryNameLengthValidation = 'categoryNameLengthValidation',
  addCategoryDescription = 'addCategoryDescription',
  openCategory = 'openCategory',
  changePositionCategory = 'changePositionCategory',
  DownloadInvoicesReport_Tooltip_Header = 'DownloadInvoicesReport_Tooltip_Header',
  DownloadInvoicesReport_Tooltip_Content = 'DownloadInvoicesReport_Tooltip_Content',
  DownloadInvoicesReport_Tooltip_Content_NoFilter = 'DownloadInvoicesReport_Tooltip_Content_NoFilter',
  gridNoFilteredData = 'gridNoFilteredData',
  gridNoData = 'gridNoData',
  addAnother = 'addAnother',
  addAnotherNip = 'addAnotherNip',
  number = 'number',
  NIPPesel = 'NIPPesel',
  agentConfigureCheck = 'agentConfigureCheck',
  desktopConnectionCheck = 'desktopConnectionCheck',
  agentUnavailableErrorTitle = 'agentUnavailableErrorTitle',
  agentUnavailableErrorContent = 'agentUnavailableErrorContent',
  externalFinancialYearsPending = 'externalFinancialYearsPending',
  externalFinancialYearsErrorTitle = 'externalFinancialYearsErrorTitle',
  externalFinancialYearsErrorContent = 'externalFinancialYearsErrorContent',
  externalContractorsPending = 'externalContractorsPending',
  externalContractorsErrorTitle = 'externalContractorsErrorTitle',
  externalContractorsErrorContent = 'externalContractorsErrorContent',
  externalAccountsPending = 'externalAccountsPending',
  externalAccountsErrorTitle = 'externalAccountsErrorTitle',
  externalAccountsErrorContent = 'externalAccountsErrorContent',
  externalDictionariesPending = 'externalDictionariesPending',
  externalDictionariesErrorTitle = 'externalDictionariesErrorTitle',
  externalDictionariesErrorContent = 'externalDictionariesErrorContent',
  externalDictionaryElementsPending = 'externalDictionaryElementsPending',
  externalDictionaryElementsErrorTitle = 'externalDictionaryElementsErrorTitle',
  externalDictionaryElementsErrorContent = 'externalDictionaryElementsErrorContent',
  externalVatRegistryElementsPending = 'externalVatRegistryElementsPending',
  externalVatRegistryElementsErrorTitle = 'externalVatRegistryElementsErrorTitle',
  externalVatRegistryElementsErrorContent = 'externalVatRegistryElementsErrorContent',
  externalDocumentTypeElementsPending = 'externalDocumentTypeElementsPending',
  externalDocumentTypeElementsErrorTitle = 'externalDocumentTypeElementsErrorTitle',
  externalDocumentTypeElementsErrorContent = 'externalDocumentTypeElementsErrorContent',
  initializationCompleting = 'initializationCompleting',
  buyPackage = 'buyPackage',
  package = 'package',
  autopostingInitializationFailed = 'autopostingInitializationFailed',
  autopostingRedirectButton = 'autopostingRedirectButton',
  accounts = 'accounts',
  plan = 'plan',
  lastActualizationFromFK = 'lastActualizationFromFK',
  categoryContent = 'categoryContent',
  categoryContentTxt = 'categoryContentTxt',
  postingSettings = 'postingSettings',
  activeExpressions = 'activeExpressions',
  archivedExpressions = 'archivedExpressions',
  addNewCategory = 'addNewCategory',
  expression = 'expression',
  add = 'add',
  yourPackage = 'yourPackage',
  activeUntil = 'activeUntil',
  packageIncludes = 'packageIncludes',
  noData = 'noData',
  invoicesCount = 'invoicesCount',
  memory = 'memory',
  used = 'used',
  usedSpace = 'usedSpace',
  availableInvoices = 'availableInvoices',
  availableSpace = 'availableSpace',
  requestLimit = 'requestLimit',
  requestLimitContent = 'requestLimitContent',
  connectionWithServer_Tooltip_Demo = 'connectionWithServer_Tooltip_Demo',
  connectionWithServer_Tooltip_Prod = 'connectionWithServer_Tooltip_Prod',
  usageIconExpiring_Tooltip_Header = 'usageIconExpiring_Tooltip_Header',
  usageIconExpired_Tooltip_Header = 'usageIconExpired_Tooltip_Header',
  usageIconUsed_Tooltip_Header = 'usageIconUsed_Tooltip_Header',
  usageIconExpiryDate_Tooltip_Content = 'usageIconExpiryDate_Tooltip_Content',
  usageIconInvoiceLimit_Tooltip_Content = 'usageIconInvoiceLimit_Tooltip_Content',
  usageIconDiskSpaceLimit_Tooltip_Content = 'usageIconDiskSpaceLimit_Tooltip_Content',
  usageIconExpiryDateAndInvoiceLimit_Tooltip_Content = 'usageIconExpiryDateAndInvoiceLimit_Tooltip_Content',
  usageIconExpiryDateAndDiskSpaceLimit_Tooltip_Content = 'usageIconExpiryDateAndDiskSpaceLimit_Tooltip_Content',
  usageAllConditionsExpired_Tooltip_Content = 'usageAllConditionsExpired_Tooltip_Content',
  usageIconInvoiceLimitAndDiskSpaceLimit_Tooltip_Content = 'usageIconInvoiceLimitAndDiskSpaceLimit_Tooltip_Content',
  usageIconExpired_Tooltip_Content = 'usageIconExpired_Tooltip_Content',
  usageIconUsed_Tooltip_Content = 'usageIconUsed_Tooltip_Content',
  PackageInactiveClarification = 'PackageInactiveClarification',
  PackageInactiveBuyPackage = 'PackageInactiveBuyPackage',
  PackageInactiveContactWithAdmin = 'PackageInactiveContactWithAdmin',
  SubscriptionNotFoundMessage = 'SubscriptionNotFoundMessage',
  AccessDenied = 'AccessDenied',
  SubscriptionNotFound = 'SubscriptionNotFound',
  categoryDefault = 'categoryDefault',
  categoryCustom = 'categoryCustom',
  range = 'range',
  amount = 'amount',
  searchAmount = 'searchAmount',
  description_STORAGE = 'description_STORAGE',
  description_INVOICE = 'description_INVOICE',
  subscriptionFormTitle = 'subscriptionFormTitle',
  subcriptionNumber = 'subcriptionNumber',
  serialNumber = 'serialNumber',
  serialNumberTooltip_Content = 'serialNumberTooltip_Content',
  serialNumberTooltip_Header = 'serialNumberTooltip_Header',
  serialNumberInUseModal_Content = 'serialNumberInUseModal_Content',
  serialNumberInUseModal_Header = 'serialNumberInUseModal_Header',
  serialNumberWrongModal_Content = 'serialNumberWrongModal_Content',
  serialNumberWrongModal_Header = 'serialNumberWrongModal_Header',
  serialNumberGenericMessage = 'serialNumberGenericMessage',
  acknowledge = 'acknowledge',
  eBok = 'eBok',
  categoryName = 'categoryName',
  previousCategory = 'previousCategory',
  user = 'user',
  current = 'current',
  archival = 'archival',
  wrongSerialNumberError = 'wrongSerialNumberError',
  usedSerialNumberError = 'usedSerialNumberError',
  LoadingPackageStatisticError = 'LoadingPackageStatisticError',
  inactiveFrom = 'inactiveFrom',
  categoryAccountSaveFailed = 'categoryAccountSaveFailed',
  editCategory = 'editCategory',
  deleteCategory = 'deleteCategory',
  accessDeniedDesktopTitle = 'accessDeniedDesktopTitle',
  accessDeniedDesktopDescription = 'accessDeniedDesktopDescription',
  changeCategory = 'changeCategory',
  writeCategoryName = 'writeCategoryName',
  categoryAdded = 'categoryAdded',
  chooseAccount = 'chooseAccount',
  choose = 'choose',
  search = 'search',
  settlementsTxt = 'settlementsTxt',
  dateLabel = 'dateLabel',
  changeCategoryNameHeader = 'changeCategoryNameHeader',
  categoryNameChanged = 'categoryNameChanged',
  categoryNameChangedFailed = 'categoryNameChangedFailed',
  ksefHealth_DOWN_tooltip_Content = 'ksefHealth_DOWN_tooltip_Content',
  ksefHealth_UNKNOWN_tooltip_Content = 'ksefHealth_UNKNOWN_tooltip_Content',
  ksefHealth_OPERATIONAL_tooltip_Content = 'ksefHealth_OPERATIONAL_tooltip_Content',
  packageUsageTooltipHeader = 'packageUsageTooltipHeader',
  packageUsageTooltipContent = 'packageUsageTooltipContent',
  ksefStatusError = 'ksefStatusError',
  use = 'use',
  archive = 'archive',
  saveAutoPostingSettings = 'saveAutoPostingSettings',
  settlementsSaveFailed = 'settlementsSaveFailed',
  categoryAlreadyExist = 'categoryAlreadyExist',
  categoryAlreadyExistTxt = 'categoryAlreadyExistTxt',
  change = 'change',
  whatIfind = 'whatIfind',
  whatIConfigure = 'whatIConfigure',
  noDataToDisplay = 'noDataToDisplay',
  deleteCategoryWithCategoryRulesHeader = 'deleteCategoryWithCategoryRulesHeader',
  deleteCategoryWithCategoryRulesDescription = 'deleteCategoryWithCategoryRulesDescription',
  moveCategoryRules = 'moveCategoryRules',
  deleteCategoryWithCategoryRules = 'deleteCategoryWithCategoryRules',
  deleteCategoryHeader = 'deleteCategoryHeader',
  deleteCategoryDescription = 'deleteCategoryDescription',
  chooseNewCategoryDescription = 'chooseNewCategoryDescription',
  moveCategoryRulesAndRemoveCategory = 'moveCategoryRulesAndRemoveCategory',
  moveToCategory = 'moveToCategory',
  categoryRulesList = 'categoryRulesList',
  categoryDeleted = 'categoryDeleted',
  categoryDeletedFailed = 'categoryDeletedFailed',
  previewMode = 'previewMode',
  type = 'type',
  PURCHASE = 'PURCHASE',
  SALES = 'SALES',
  CategoryIds = 'CategoryIds',
  AccountsKUP = 'AccountsKUP',
  AccountsNKUP = 'AccountsNKUP',
  InvoiceTypeGroup = 'InvoiceTypeGroup',
  resetStarted = 'resetStarted',
  resetFailed = 'resetFailed',
  missingKsefInvoicesTitle = 'missingKsefInvoicesTitle',
  permissionRegisterOperationGRANT = 'permissionRegisterOperationGRANT',
  permissionRegisterOperationREVOKE = 'permissionRegisterOperationREVOKE',
  permissionRegisterOperationUNDEFINED = 'permissionRegisterOperationUNDEFINED',
  authSubTokenInProgress = 'authSubTokenInProgress',
  authSubTokenSuccess = 'authSubTokenSuccess',
  authSubTokenError = 'authSubTokenError',
  authSubSignatureInProgress = 'authSubSignatureInProgress',
  authSubSignatureSuccess = 'authSubSignatureSuccess',
  authSubSignatureError = 'authSubSignatureError',
  authGeneralError = 'authGeneralError',
  unAuthSubSuccess = 'unAuthSubSuccess',
  unAuthSubError = 'unAuthSubError',
  unAuthSubPending = 'unAuthSubPending',
  permissionRegisterStatus_SendToKsef = 'permissionRegisterStatus_SendToKsef',
  permissionRegisterStatus_RejectedByKsef = 'permissionRegisterStatus_RejectedByKsef',
  permissionRegisterStatus_Pending = 'permissionRegisterStatus_Pending',
  removePermissionButtonTooltip_Content = 'removePermissionButtonTooltip_Content',
  deletePermissionMenuItemSuccess = 'deletePermissionMenuItemSuccess',
  pendingAuthorization = 'pendingAuthorization',
  pendingUnauthorization = 'pendingUnauthorization',
  downloadInvoicesSubSuccess = 'downloadInvoicesSubSuccess',
  downloadInvoicesSubInProgress = 'downloadInvoicesSubInProgress',
  ksefConnectionError = 'ksefConnectionError',
  subscriptionError = 'subscriptionError',
  selfInvoicingDescription = 'selfInvoicingDescription',
  removeSelfInvoicing = 'removeSelfInvoicing',
  deleteSelfInvoicingActionTitle = 'deleteSelfInvoicingActionTitle',
  deleteSelfInvoicingActionContent = 'deleteSelfInvoicingActionContent',
  addSelfInvoicingToExistingNIPError = 'addSelfInvoicingToExistingNIPError',
  addKSeFPermissionInProgress = 'addKSeFPermissionInProgress',
  editKSeFPermissionInProgress = 'editKSeFPermissionInProgress',
  removeKSeFPermissionInProgress = 'removeKSeFPermissionInProgress',
  addKSeFPermissionError = 'addKSeFPermissionError',
  editKSeFPermissionError = 'editKSeFPermissionError',
  removeKSeFPermissionError = 'removeKSeFPermissionError',
  addKSeFPermissionSuccess = 'addKSeFPermissionSuccess',
  editKSeFPermissionSuccess = 'editKSeFPermissionSuccess',
  removeKSeFPermissionSuccess = 'removeKSeFPermissionSuccess',
  grantKSeFPermissionSubSuccess = 'grantKSeFPermissionSubSuccess',
  getUpoSubInProgress = 'getUpoSubInProgress',
  getUpoSubSuccess = 'getUpoSubSuccess',
  getUpoSubPartialSuccess = 'getUpoSubPartialSuccess',
  getUpoSubError = 'getUpoSubError',
  getUpoFileError = 'getUpoFileError',
  expiredTokenTitle = 'expiredTokenTitle',
  expiredTokenDescription = 'expiredTokenDescription',
  expiredTokenGoToAuthorize = 'expiredTokenGoToAuthorize',
  restoreExpression = 'restoreExpression',
  deleteExpression = 'deleteExpression',
  postingSettingsTitle = 'postingSettingsTitle',
  postingSettingsTitlePopover = 'postingSettingsTitlePopover',
  postingSettingsTitlePopoverText = 'postingSettingsTitlePopoverText',
  postingSettingsListDescription = 'postingSettingsListDescription',
  VAT = 'VAT',
  CORRECTIVE = 'CORRECTIVE',
  SIMPLIFIED = 'SIMPLIFIED',
  ADVANCE_BILLING = 'ADVANCE_BILLING',
  CORRECTIVE_ADVANCE_BILLING = 'CORRECTIVE_ADVANCE_BILLING',
  UNDEFINED = 'UNDEFINED',
  Specific = 'Specific',
  DateFrom = 'DateFrom',
  DateTo = 'DateTo',
  ModifiedAt = 'ModifiedAt',
  CategoryNames = 'CategoryNames',
  UserIds = 'UserIds',
  AccountNKUPIds = 'AccountNKUPIds',
  AccountKUPIds = 'AccountKUPIds',
  PositionName = 'PositionName',
  vehicles = 'vehicles',
  vehiclesConfiguration = 'vehiclesConfiguration',
  addVehicle = 'addVehicle',
  licencePlate = 'licencePlate',
  writeLicencePlate = 'writeLicencePlate',
  licencePlateValueInputError = 'licencePlateValueInputError',
  vehicleDescriptionLengthInputError = 'vehicleDescriptionLengthInputError',
  VehicleUsageTypes = 'VehicleUsageTypes',
  VehicleUsageType = 'VehicleUsageType',
  RegistrationNumber = 'RegistrationNumber',
  PrivateVehicle = 'PrivateVehicle',
  MixedVehicle = 'MixedVehicle',
  CompanyVehicle = 'CompanyVehicle',
  description = 'description',
  writeDescription = 'writeDescription',
  deleteVehicle = 'deleteVehicle',
  editVehicle = 'editVehicle',
  registrationNumberPlate = 'registrationNumberPlate',
  deleteVehicleHeader = 'deleteVehicleHeader',
  deleteVehicleDescription = 'deleteVehicleDescription',
  deleteVehicleSuccess = 'deleteVehicleSuccess',
  deleteVehicleFailed = 'deleteVehicleFailed',
  updateVehicleSuccess = 'updateVehicleSuccess',
  updateVehicleFailed = 'updateVehicleFailed',
  wsEventsHistory = 'wsEventsHistory',
  wsEventsContent = 'wsEventsContent',
  wsUnreadOnlyBtn = 'wsUnreadOnlyBtn',
  wsMarkAsRead = 'wsMarkAsRead',
  message = 'message',
  AuthorizeInKSeF = 'AuthorizeInKSeF',
  AuthorizeInKSeFExternalToken = 'AuthorizeInKSeFExternalToken',
  UnauthorizedInKSeF = 'UnauthorizedInKSeF',
  UnauthorizedInKSeFExternalToken = 'UnauthorizedInKSeFExternalToken',
  AutoUnauthorizedInKSeFExternalToken = 'AutoUnauthorizedInKSeFExternalToken',
  DownloadInvoices = 'DownloadInvoices',
  GetUPO = 'GetUPO',
  TerminateSession = 'TerminateSession',
  PermissionOperation = 'PermissionOperation',
  AutoFetchingInvoices = 'AutoFetchingInvoices',
  goToWSEvents = 'goToWSEvents',
  unreadMessageTooltip = 'unreadMessageTooltip',
  wsEventsGridNoUnreadData = 'wsEventsGridNoUnreadData',
  loginHubSetTenant = 'loginHubSetTenant',
  loginHubChooseTenant = 'loginHubChooseTenant',
  authorizedManagePermissionsOnlyPageDesc = 'authorizedManagePermissionsOnlyPageDesc',
  missingKsefInvoicesContentWithCount = 'missingKsefInvoicesContentWithCount',
  grantKSeFPermissionSubError = 'grantKSeFPermissionSubError',
  getUPOResultTitle = 'getUPOResultTitle',
  getUPOResultContent = 'getUPOResultContent',
  downloadUPO = 'downloadUPO',
  showGettingInvoicesReport = 'showGettingInvoicesReport',
  unreadMessages = 'unreadMessages',
  wsNotificationsHistory = 'wsNotificationsHistory',
  eventDate = 'eventDate',
  showMessage = 'showMessage',
  info = 'info',
  error = 'error',
  success = 'success',
  ksefAutoFetchingInvoicesSubSuccess = 'ksefAutoFetchingInvoicesSubSuccess',
  ksefAutoFetchingInvoicesPartialSubSuccess = 'ksefAutoFetchingInvoicesPartialSubSuccess',
  ksefAutoFetchingInvoicesSubError = 'ksefAutoFetchingInvoicesSubError',
  missingKsefAutoFetchInvoicesTitle = 'missingKsefAutoFetchInvoicesTitle',
  TerminateKSeFSessionButtonTooltip_Header = 'TerminateKSeFSessionButtonTooltip_Header',
  TerminateKSeFSessionButtonTooltip_Content = 'TerminateKSeFSessionButtonTooltip_Content',
  operationType = 'operationType',
  COMPANY = 'COMPANY',
  MIXED = 'MIXED',
  PRIVATE = 'PRIVATE',
  vehicleSaveError = 'vehicleSaveError',
  vehicleSaveSuccess = 'vehicleSaveSuccess',
  licencePlateExistsInputError = 'licencePlateExistsInputError',
  registrationNumber = 'registrationNumber',
  postingInitializationCompleted = 'postingInitializationCompleted',
  licencePlateLengthInputError = 'licencePlateLengthInputError',
  postingPartlyNotAvailableModalTitle = 'postingPartlyNotAvailableModalTitle',
  postingPartlyNotAvailableModalText = 'postingPartlyNotAvailableModalText',
  sentInvoicesForPostingSuccess = 'sentInvoicesForPostingSuccess',
  readyForPosting = 'readyForPosting',
  invoicePostingStatus = 'invoicePostingStatus',
  invoicesDatesHeader = 'invoicesDatesHeader',
  invoicesDatesContent = 'invoicesDatesContent',
  fetchingInvoicesModalTooltipContent = 'fetchingInvoicesModalTooltipContent',
  firstFetchingInvoicesModalTooltipContent = 'firstFetchingInvoicesModalTooltipContent',
  autoFetchingInvoicesLink = 'autoFetchingInvoicesLink',
  minDate = 'minDate',
  maxDate = 'maxDate',
  invalidDate = 'invalidDate',
  outOfRange = 'outOfRange',
  download = 'download',
  inBackground = 'inBackground',
  userCannotDeleteOwnersPermissions_Tooltip = 'userCannotDeleteOwnersPermissions_Tooltip',
  userCannotEditOwnersPermissions_Tooltip = 'userCannotEditOwnersPermissions_Tooltip',
  ContractorId = 'ContractorId',
  ContractorName = 'ContractorName',
  ContractorIdentifier = 'ContractorIdentifier',
  Identifier = 'Identifier',
  PostingDate = 'PostingDate',
  PostingStatus = 'PostingStatus',
  identifierSender = 'identifierSender',
  documentType = 'documentType',
  decre_sent = 'decre_sent',
  incomplete = 'incomplete',
  INCOMPLETE = 'INCOMPLETE',
  noaction = 'noaction',
  posted = 'posted',
  invoicePURCHASE = 'invoicePURCHASE',
  invoiceSALES = 'invoiceSALES',
  allDecrees = 'allDecrees',
  decreeReadyToSent = 'decreeReadyToSent',
  decree_sent = 'decree_sent',
  DECREE_SENT = 'DECREE_SENT',
  DECREE_DOWNLOADED = 'DECREE_DOWNLOADED',
  FAILED = 'FAILED',
  NO_ACTION = 'NO_ACTION',
  POSTED = 'POSTED',
  publishInvoicesAction_error = 'publishInvoicesAction_error',
  publishInvoicesAction_success = 'publishInvoicesAction_success',
  editDecree = 'editDecree',
  refreshDecree = 'refreshDecree',
  invoiceWithoutDecreeModalTitle = 'invoiceWithoutDecreeModalTitle',
  invoiceWithoutDecreeModalText = 'invoiceWithoutDecreeModalText',
  sum = 'sum',
  nullDate = 'nullDate',
  completeTheDate = 'completeTheDate',
  backToDecreeList = 'backToDecreeList',
  invoiceWithTypeGroupAndNumber = 'invoiceWithTypeGroupAndNumber',
  decreeStatus = 'decreeStatus',
  hideDetails = 'hideDetails',
  invoiceWithTypeGroup = 'invoiceWithTypeGroup',
  invoicePreview = 'invoicePreview',
  previewDecree = 'previewDecree',
  showDetails = 'showDetails',
  contractorWithName = 'contractorWithName',
  vatRegistry = 'vatRegistry',
  vatRate = 'vatRate',
  net = 'net',
  vatValue = 'vatValue',
  gtu = 'gtu',
  vatRates = 'vatRates',
  dateOfIssueWithValue = 'dateOfIssueWithValue',
  KSeFNumberWithValue = 'KSeFNumberWithValue',
  identifierWithValue = 'identifierWithValue',
  KSeFDateWithValue = 'KSeFDateWithValue',
  currencyRateWithValue = 'currencyRateWithValue',
  addressWithValue = 'addressWithValue',
  goodsServicesRecivedDateWithValue = 'goodsServicesRecivedDateWithValue',
  invoiceTypeWithValue = 'invoiceTypeWithValue',
  cityWithValue = 'cityWithValue',
  paymentTypeWithValue = 'paymentTypeWithValue',
  zipCodeWithValue = 'zipCodeWithValue',
  paymentDateWithValue = 'paymentDateWithValue',
  chooseVatRegistries = 'chooseVatRegistries',
  downloadToFK = 'downloadToFK',
  downloadTo = 'downloadTo',
  requiredField = 'requiredField',
  backgroundInvoiceSending = 'backgroundInvoiceSending',
  dailyPeriodSwitchLabel = 'dailyPeriodSwitchLabel',
  sendAtTime = 'sendAtTime',
  intervalPeriodSwitchLabel = 'intervalPeriodSwitchLabel',
  sendInterval = 'sendInterval',
  hours = 'hours',
  hours2 = 'hours2',
  invoiceSendSwitchTooltip = 'invoiceSendSwitchTooltip',
  whoActivated = 'whoActivated',
  whoDeactivated = 'whoDeactivated',
  pick = 'pick',
  autoSendInvoiceTooltip_Content = 'autoSendInvoiceTooltip_Content',
  autoSendInvoiceTooltip_Header = 'autoSendInvoiceTooltip_Header',
  autoActionsInBackground = 'autoActionsInBackground',
  submitFormError = 'submitFormError',
  submitFormSuccess = 'submitFormSuccess',
  AutoSendingInvoices = 'AutoSendingInvoices',
  SendingInvoices = 'SendingInvoices',
  UploadInvoices = 'UploadInvoices',
  OcrInvoiceImported = 'OcrInvoiceImported',
  choosePlaceholder = 'choosePlaceholder',
  netValueFrom = 'netValueFrom',
  netValueTo = 'netValueTo',
  vatValueFrom = 'vatValueFrom',
  vatValueTo = 'vatValueTo',
  grossValueFrom = 'grossValueFrom',
  grossValueTo = 'grossValueTo',
  PostingCreatedAtSpecific = 'PostingCreatedAtSpecific',
  PostingCreatedAtFrom = 'PostingCreatedAtFrom',
  PostingCreatedAtTo = 'PostingCreatedAtTo',
  netValueSearchAmount = 'netValueSearchAmount',
  vatValueSearchAmount = 'vatValueSearchAmount',
  grossValueSearchAmount = 'grossValueSearchAmount',
  Nip = 'Nip',
  unknownNotificationType = 'unknownNotificationType',
  decreeChangeSuccess = 'decreeChangeSuccess',
  configurationRequired = 'configurationRequired',
  configurationRequiredMessage = 'configurationRequiredMessage',
  decreeConfigure = 'decreeConfigure',
  userNotAuthorized_title = 'userNotAuthorized_title',
  userNotAuthorized_content = 'userNotAuthorized_content',
  noCompanyAssigned_title = 'noCompanyAssigned_title',
  noCompanyAssigned_content = 'noCompanyAssigned_content',
  chooseCompanyRedirect = 'chooseCompanyRedirect',
  nonEmptyField = 'nonEmptyField',
  amountsDoNotMatch = 'amountsDoNotMatch',
  decreeConfigurationRequiredModalTitle = 'decreeConfigurationRequiredModalTitle',
  decreeConfigurationRequiredModalText = 'decreeConfigurationRequiredModalText',
  categoryAccountsRequiredText = 'categoryAccountsRequiredText',
  settlementsRequiredText = 'settlementsRequiredText',
  vatRegistriesRequiredText = 'vatRegistriesRequiredText',
  apTemporaryTestVersion = 'apTemporaryTestVersion',
  notificationType = 'notificationType',
  changeVehicleUsageType = 'changeVehicleUsageType',
  changeAccountType = 'changeAccountType',
  configureAutodecree = 'configureAutodecree',
  getPostingConfigurationStatusFailed = 'getPostingConfigurationStatusFailed',
  saveConfigurationBlockedExplanation = 'saveConfigurationBlockedExplanation',
  whyCantSaveConfigurationTxt = 'whyCantSaveConfigurationTxt',
  whyCantSaveConfigurationTxtSettings = 'whyCantSaveConfigurationTxtSettings',
  ksefOperationError = 'ksefOperationError',
  ksefRequestLimit = 'ksefRequestLimit',
  ksefRequestLimitTime = 'ksefRequestLimitTime',
  useInDecree = 'useInDecree',
  next = 'next',
  addToConfiguration = 'addToConfiguration',
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
  CHECK = 'CHECK',
  CREDIT = 'CREDIT',
  MOBILE = 'MOBILE',
  VOUCHER = 'VOUCHER',
  useInMechanism = 'useInMechanism',
  addVehicleModalMessage = 'addVehicleModalMessage',
  editVehicleModalMessage = 'editVehicleModalMessage',
  Lp = 'Lp',
  amountWn = 'amountWn',
  amountMa = 'amountMa',
  addNewVehicle = 'addNewVehicle',
  searchRegistrationNumber = 'searchRegistrationNumber',
  sessionIsNotTerminated = 'sessionIsNotTerminated',
  sessionIsNotTerminatedMessage = 'sessionIsNotTerminatedMessage',
  showOngoingSessionProcesses = 'showOngoingSessionProcesses',
  ongoingSessionProcesses = 'ongoingSessionProcesses',
  waitAndDownload = 'waitAndDownload',
  abandon = 'abandon',
  defaultKsefErrorMessage = 'defaultKsefErrorMessage',
  registrationNumberCreatedMessage = 'registrationNumberCreatedMessage',
  registrationNumberCreatedErrorMessage = 'registrationNumberCreatedErrorMessage',
  appVersion = 'appVersion',
  InvoicePostingStatusChanged = 'InvoicePostingStatusChanged',
  RegistrationNumberCreated = 'RegistrationNumberCreated',
  accountType = 'accountType',
  chooseAccountType = 'chooseAccountType',
  createCategory = 'createCategory',
  uploadInvoice_tooltipContent = 'uploadInvoice_tooltipContent',
  addFilesForUpload = 'addFilesForUpload',
  addFilesForOcrUpload = 'addFilesForOcrUpload',
  showDocumentsToVerification = 'showDocumentsToVerification',
  uploadFilesWindowHeader = 'uploadFilesWindowHeader',
  uploadFilesWindowContent = 'uploadFilesWindowContent',
  uploadOneBoxContent = 'uploadOneBoxContent',
  uploadManyBoxContent = 'uploadManyBoxContent',
  swapTheFile = 'swapTheFile',
  chooseFileFromDisk = 'chooseFileFromDisk',
  acceptedFiles = 'acceptedFiles',
  rejectedFiles = 'rejectedFiles',
  addedFile = 'addedFile',
  addedFiles = 'addedFiles',
  numberOfAddedDocuments = 'numberOfAddedDocuments',
  endAddingFiles = 'endAddingFiles',
  uploadOneError = 'uploadOneError',
  uploadManyError = 'uploadManyError',
  uploadOneInvalidType = 'uploadOneInvalidType',
  uploadOneExceed = 'uploadOneExceed',
  uploadOneInvalidSize = 'uploadOneInvalidSize',
  uploadOneInvalidMixedReasons = 'uploadOneInvalidMixedReasons',
  uploadManyInvalidMixedReasons = 'uploadManyInvalidMixedReasons',
  uploadManyInvalidType = 'uploadManyInvalidType',
  uploadManyExceed = 'uploadManyExceed',
  uploadManyInvalidSize = 'uploadManyInvalidSize',
  missingUploadInvoicesTitle = 'missingUploadInvoicesTitle',
  missingUploadInvoicesContent = 'missingUploadInvoicesContent',
  showMissingUploadInvoices = 'showMissingUploadInvoices',
  showMissingUploadInvoicesButtonLabel = 'showMissingUploadInvoicesButtonLabel',
  uploadInvoiceSuccess = 'uploadInvoiceSuccess',
  uploadInvoiceError = 'uploadInvoiceError',
  uploadInvoicesSuccess = 'uploadInvoicesSuccess',
  uploadInvoicesError = 'uploadInvoicesError',
  uploadInvoiceInProgress = 'uploadInvoiceInProgress',
  uploadInvoicesInProgress = 'uploadInvoicesInProgress',
  Id = 'Id',
  RegistrationNumbers = 'RegistrationNumbers',
  sendToFKUnavailable = 'sendToFKUnavailable',
  sendToFKUnavailableTxt = 'sendToFKUnavailableTxt',
  allRadioLabel = 'allRadioLabel',
  internalRadioLabel = 'internalRadioLabel',
  externalRadioLabel = 'externalRadioLabel',
  autoFetchingSwitchTooltip = 'autoFetchingSwitchTooltip',
  dateLessThenStart = 'dateLessThenStart',
  cantDownloadUpoTooltip = 'cantDownloadUpoTooltip',
  selfInvoicingUpoTooltip = 'selfInvoicingUpoTooltip',
  changingDefaultDate = 'changingDefaultDate',
  returnLabel = 'returnLabel',
  missingCategoryLabel = 'missingCategoryLabel',
  missingAccountLabel = 'missingAccountLabel',
  invoicesDatesHeaderNew = 'invoicesDatesHeaderNew',
  invoicesDatesContentNew = 'invoicesDatesContentNew',
  previousMonth = 'previousMonth',
  nextMonth = 'nextMonth',
  downloadInvoiceToFKSuccess = 'downloadInvoiceToFKSuccess',
  downloadInvoiceToFKInfo = 'downloadInvoiceToFKInfo',
  downloadInvoicesToFKSuccess = 'downloadInvoicesToFKSuccess',
  downloadInvoiceToFKError = 'downloadInvoiceToFKError',
  downloadInvoicesToFKError = 'downloadInvoicesToFKError',
  emptyDate = 'emptyDate',
  VAT_23 = 'VAT_23',
  VAT_22 = 'VAT_22',
  VAT_8 = 'VAT_8',
  VAT_7 = 'VAT_7',
  VAT_5 = 'VAT_5',
  VAT_4 = 'VAT_4',
  VAT_3 = 'VAT_3',
  VAT_0 = 'VAT_0',
  VAT_ZW = 'VAT_ZW',
  VAT_OO = 'VAT_OO',
  VAT_NP = 'VAT_NP',
  VAT_OSS = 'VAT_OSS',
  apTestConfigurationHeader = 'apTestConfigurationHeader',
  apTestConfigurationMsg = 'apTestConfigurationMsg',
  writeUs = 'writeUs',
  exportToFinancesAndAccountancyPartlyNotAvailableModalTitle = 'exportToFinancesAndAccountancyPartlyNotAvailableModalTitle',
  exportToFinancesAndAccountancyPartlyNotAvailableModalText = 'exportToFinancesAndAccountancyPartlyNotAvailableModalText',
  ocrInvoiceTooltip = 'ocrInvoiceTooltip',
  invoicesUploadTooltip = 'invoicesUploadTooltip',
  invoicesUploadXMLTooltip = 'invoicesUploadXMLTooltip',
  invoicesUploadOcrTooltip = 'invoicesUploadOcrTooltip',
  ocrImportedToKSeFSuccess = 'ocrImportedToKSeFSuccess',
  ocrImportedToKSeFError = 'ocrImportedToKSeFError',
  pageNotFoundError = 'pageNotFoundError',
  pageNotFoundTitle = 'pageNotFoundTitle',
  pageNotFoundDescription = 'pageNotFoundDescription',
  pageNotFoundRedirectButton = 'pageNotFoundRedirectButton',
  documentTypes = 'documentTypes',
  InvoicePostingStatuses = 'InvoicePostingStatuses',
  autoPostingDocuments = 'autoPostingDocuments',
  autoPostingDocumentsDescription = 'autoPostingDocumentsDescription',
  confirmDocsBeforeSendingToFK = 'confirmDocsBeforeSendingToFK',
  confirmDocsBeforeSendingToFKDescription = 'confirmDocsBeforeSendingToFKDescription',
  possibilityToAcceptAllDecrees = 'possibilityToAcceptAllDecrees',
  lackOfPossibilityToAcceptOwnDecrees = 'lackOfPossibilityToAcceptOwnDecrees',
  documentTypesTitle = 'documentTypesTitle',
  documentTypesTitlePopover = 'documentTypesTitlePopover',
  documentTypesTitlePopoverText = 'documentTypesTitlePopoverText',
  saveSuccess = 'saveSuccess',
  saveError = 'saveError',
  autoPostingSwitchStateInfo = 'autoPostingSwitchStateInfo',
  deactivatedSwitch = 'deactivatedSwitch',
  PostInvoicesFailed = 'PostInvoicesFailed',
  postInvoicesFailedErrorMessage = 'postInvoicesFailedErrorMessage',
  sendToKsefExtendedModal = 'sendToKsefExtendedModal',
  acceptDecree = 'acceptDecree',
  acceptDecreeDisableTooltip = 'acceptDecreeDisableTooltip',
  getCurrentEnvironmentFailed = 'getCurrentEnvironmentFailed',
  systemErrorMessage = 'systemErrorMessage',
  deleteInvoicesError = 'deleteInvoicesError',
  deleteSingleInvoiceError = 'deleteSingleInvoiceError',
  deleteInvoicesSuccess = 'deleteInvoicesSuccess',
  deleteInvoicesPartialSuccess = 'deleteInvoicesPartialSuccess',
  deleteInvoiceSuccess = 'deleteInvoiceSuccess',
  deleteInvoiceInProgress = 'deleteInvoiceInProgress',
  deleteInvoiceFinished = 'deleteInvoiceFinished',
  deletedMissedInvoicesModalTitle = 'deletedMissedInvoicesModalTitle',
  deletedMissedInvoicesModalContent = 'deletedMissedInvoicesModalContent',
  InvoiceNonRemovableReasonNone = 'InvoiceNonRemovableReasonNone',
  InvoiceNonRemovableReasonNotOwner = 'InvoiceNonRemovableReasonNotOwner',
  InvoiceNonRemovableReasonWrongType = 'InvoiceNonRemovableReasonWrongType',
  InvoiceNonRemovableReasonWrongStatus = 'InvoiceNonRemovableReasonWrongStatus',
  InvoiceNonRemovableReasonAlreadyExported = 'InvoiceNonRemovableReasonAlreadyExported',
  InvoiceNonRemovableReasonServerError = 'InvoiceNonRemovableReasonServerError',
  InvoiceNonRemovableReasonNotFound = 'InvoiceNonRemovableReasonNotFound',
  cantDeleteInvoiceTooltip = 'cantDeleteInvoiceTooltip',
  deleteInvoiceConfirmationModalTitle = 'deleteInvoiceConfirmationModalTitle',
  deleteInvoicesConfirmationModalTitle = 'deleteInvoicesConfirmationModalTitle',
  deleteInvoiceConfirmationModalContent = 'deleteInvoiceConfirmationModalContent',
  dateFromTo = 'dateFromTo',
  selected = 'selected',
  elementsSelected = 'elementsSelected',
  elementsSelectedFromTheList = 'elementsSelectedFromTheList',
  uncheck = 'uncheck',
  exportReportModalTitle = 'exportReportModalTitle',
  export = 'export',
  exportReportModalContent = 'exportReportModalContent',
  exportReportModalContentNoFilter = 'exportReportModalContentNoFilter',
  invoicesDatesTooltip = 'invoicesDatesTooltip',
  dateRange90days = 'dateRange90days',
  dateRange2Years = 'dateRange2Years',
  noAcceptedDecree = 'noAcceptedDecree',
  someInvoicesWithoutDecree = 'someInvoicesWithoutDecree',
  someInvoicesWithoutDecreeModal = 'someInvoicesWithoutDecreeModal',
  noDecree = 'noDecree',
  decreeAccepted = 'decreeAccepted',
  acceptationForbidden = 'acceptationForbidden',
  acceptationForbiddenText = 'acceptationForbiddenText',
  cantAcceptOwnDecree = 'cantAcceptOwnDecree',
  saveAndAcceptDecree = 'saveAndAcceptDecree',
  decreesToAccept = 'decreesToAccept',
  documentPosted = 'documentPosted',
  acceptedSuccess = 'acceptedSuccess',
  acceptedOneSuccess = 'acceptedOneSuccess',
  acceptedFailure = 'acceptedFailure',
  automaticDecrees = 'automaticDecrees',
  more = 'more',
  From = 'From',
  To = 'To',
  less = 'less',
  documentMustBeAcceptBySomeoneElse = 'documentMustBeAcceptBySomeoneElse',
  available = 'available',
  tokenExpiredAlert = 'tokenExpiredAlert',
  allNotifications = 'allNotifications',
  unreadNotifications = 'unreadNotifications',
  tokenAuthDesc = 'tokenAuthDesc',
  signAuthDesc = 'signAuthDesc',
  onlineDocumentation = 'onlineDocumentation',
  whiteListSwitchLabel = 'whiteListSwitchLabel',
  whiteListInfo = 'whiteListInfo',
  whiteListActivationInfo = 'whiteListActivationInfo',
  whiteListChangeError = 'whiteListChangeError',
  whiteListCheckPartialError = 'whiteListCheckPartialError',
  whiteListCheckAllAccountsIsEmpty = 'whiteListCheckAllAccountsIsEmpty',
  whiteListCheckAction = 'whiteListCheckAction',
  whiteListCheckInProgress = 'whiteListCheckInProgress',
  whiteListCheckRunError = 'whiteListCheckRunError',
  whiteListCheckSuccess = 'whiteListCheckSuccess',
  whiteListCheckSuccessWithNotWhiteListed = 'whiteListCheckSuccessWithNotWhiteListed',
  whiteListCheckError = 'whiteListCheckError',
  whiteListValidation = 'whiteListValidation',
  whiteListModalTitle = 'whiteListModalTitle',
  whiteListModalContent = 'whiteListModalContent',
  whiteListModalShowIssuersButton = 'whiteListModalShowIssuersButton',
  whiteListModalTableTitle = 'whiteListModalTableTitle',
  whiteListModalSubTitle = 'whiteListModalSubTitle',
  whiteListValidatedIssuerTooltipTitle = 'whiteListValidatedIssuerTooltipTitle',
  whiteListNotValidatedIssuerTooltipTitle = 'whiteListNotValidatedIssuerTooltipTitle',
  allIssuerAccountsIsValidatedInWhiteList = 'allIssuerAccountsIsValidatedInWhiteList',
  allIssuerAccountsIsNotValidatedInWhiteList = 'allIssuerAccountsIsNotValidatedInWhiteList',
  someIssuerAccountsIsValidatedInWhiteList = 'someIssuerAccountsIsValidatedInWhiteList',
  whiteListAccountsModalContent = 'whiteListAccountsModalContent',
  whiteListValidationDate = 'whiteListValidationDate',
  accountsCheckedInWhiteList = 'accountsCheckedInWhiteList',
  isWhiteListed = 'isWhiteListed',
  isNotWhiteListed = 'isNotWhiteListed',
  issuerWithoutAccounts = 'issuerWithoutAccounts',
  checkWhiteListInterrupted = 'checkWhiteListInterrupted',
  checkWhiteListInterruptedContent = 'checkWhiteListInterruptedContent',
  showResult = 'showResult',
  authorizedByDesc = 'authorizedByDesc',
  personalData = 'personalData',
  backToPermissions = 'backToPermissions',
  SelfInvoicing = 'SelfInvoicing',
  wrongNip = 'wrongNip',
  wrongPesel = 'wrongPesel',
  typesOfDocuments = 'typesOfDocuments',
  vatRegister = 'vatRegister',
  emailNotifications = 'emailNotifications',
  decreesList = 'decreesList',
  decreeDetails = 'decreeDetails',
  authorize = 'authorize',
  searchAccount = 'searchAccount',
  invoicePreviewEnabled = 'invoicePreviewEnabled',
  downloadCSV = 'downloadCSV',
  downloadXLS = 'downloadXLS',
  accountTypes = 'accountTypes',
  goBackToConfiguration = 'goBackToConfiguration',
  invoicesDateRangeExceedsTwoYearsWarning = 'invoicesDateRangeExceedsTwoYearsWarning',
  ContractorsModalGoToContractorsList = 'ContractorsModalGoToContractorsList',
  contractorAccountsNumbersListAccountHeader = 'contractorAccountsNumbersListAccountHeader',
  contractorAccountsNumbersListWhitelistHeader = 'contractorAccountsNumbersListWhitelistHeader',
  contractorAccountsNumbersListDateHeader = 'contractorAccountsNumbersListDateHeader',
  contractorAccountsNumbersListNoData = 'contractorAccountsNumbersListNoData',
  ContractorTypeSwitchesHeader = 'ContractorTypeSwitchesHeader',
  ContractorTypeSwitchesTradingLabel = 'ContractorTypeSwitchesTradingLabel',
  ContractorTypeSwitchesCostLabel = 'ContractorTypeSwitchesCostLabel',
  ContractorTypeEmptyLabel = 'ContractorTypeEmptyLabel',
  ContractorTypeSwitchesCostAndTradingLabel = 'ContractorTypeSwitchesCostAndTradingLabel',
  ContractorTypeSwitchesAllLabel = 'ContractorTypeSwitchesAllLabel',
  ContractorTypeSavedTradingAndCostAlert = 'ContractorTypeSavedTradingAndCostAlert',
  ContractorTypeSavedTradingAlert = 'ContractorTypeSavedTradingAlert',
  ContractorTypeSavedCostAlert = 'ContractorTypeSavedCostAlert',
  ContractorTypeSavedEmptyAlert = 'ContractorTypeSavedEmptyAlert',
  ContractorMultipleTypeSavedTradingAndCostAlert = 'ContractorMultipleTypeSavedTradingAndCostAlert',
  ContractorMultipleTypeSavedTradingAlert = 'ContractorMultipleTypeSavedTradingAlert',
  ContractorMultipleTypeSavedCostAlert = 'ContractorMultipleTypeSavedCostAlert',
  downloadAtTime = 'downloadAtTime',
  downloadInterval = 'downloadInterval',
  systemErrorWithoutInnerMessage = 'systemErrorWithoutInnerMessage',
  symfonia = 'symfonia',
  config = 'config',
  or = 'or',
  contentOfCategory = 'contentOfCategory',
  autopostingConfigTitle = 'autopostingConfigTitle',
  autopostingConfig = 'autopostingConfig',
  backToMainMenu = 'backToMainMenu',
  ksefSettings = 'ksefSettings',
  cancelDownloadInvoices = 'cancelDownloadInvoices',
  downloadInvoicesProgress = 'downloadInvoicesProgress',
  autoFetchingInvoicesProgress = 'autoFetchingInvoicesProgress',
  invoicesDownloadRunning = 'invoicesDownloadRunning',
  downloadLimitDate = 'downloadLimitDate',
  downloadLimitDateTooltip_Content = 'downloadLimitDateTooltip_Content',
  downloadLimitDateWarning = 'downloadLimitDateWarning',
  downloadInvoicesPending = 'downloadInvoicesPending',
  attachments = 'attachments',
  attachmentsList = 'attachmentsList',
  attached = 'attached',
  addAttachments = 'addAttachments',
  searchInTheList = 'searchInTheList',
  dateOfAdding = 'dateOfAdding',
  addedBy = 'addedBy',
  size = 'size',
  privateStatus = 'privateStatus',
  publicStatus = 'publicStatus',
  addingAttachment = 'addingAttachment',
  formatOfFile = 'formatOfFile',
  cantAddFiles = 'cantAddFiles',
  cantAddFile = 'cantAddFile',
  notSupportedFiles = 'notSupportedFiles',
  changeToPublic = 'changeToPublic',
  changeToPrivate = 'changeToPrivate',
  deletingAttachments = 'deletingAttachments',
  confirmDeletingAttachment = 'confirmDeletingAttachment',
  confirmDeletingAttachments = 'confirmDeletingAttachments',
  attachmentDeletedSuccess = 'attachmentDeletedSuccess',
  attachmentsDeletedSuccess = 'attachmentsDeletedSuccess',
  attachmentDeletedError = 'attachmentDeletedError',
  attachmentsDeletedError = 'attachmentsDeletedError',
  attachmentAddedSuccess = 'attachmentAddedSuccess',
  attachmentAddedError = 'attachmentAddedError',
  undo = 'undo',
  downloadAttachments = 'downloadAttachments',
  invoiceFormat = 'invoiceFormat',
  formatPDF = 'formatPDF',
  formatXML = 'formatXML',
  formatPDFandXML = 'formatPDFandXML',
  noInvoice = 'noInvoice',
  formatInvoiceTooltipContent = 'formatInvoiceTooltipContent',
  contractors = 'contractors',
  contractorsList = 'contractorsList',
  accountNumber = 'accountNumber',
  taxPayerStatus = 'taxPayerStatus',
  accountStatus = 'accountStatus',
  bankAccounts = 'bankAccounts',
  singleBankAccount = 'singleBankAccount',
  noAccounts = 'noAccounts',
  switchContractorType = 'switchContractorType',
  selectedContractorsCount = 'selectedContractorsCount',
  changeSelectedContractorsType = 'changeSelectedContractorsType',
  filehubMultipleActionsRequestSuccess = 'filehubMultipleActionsRequestSuccess',
  filehubMultipleActionsRequestFail = 'filehubMultipleActionsRequestFail',
  filehubNoAttachments = 'filehubNoAttachments',
  filehubDownloadedAttachmentSuccessTitle = 'filehubDownloadedAttachmentSuccessTitle',
  filehubDownloadedAttachmentFailedTitle = 'filehubDownloadedAttachmentFailedTitle',
  filehubDownloadedAttachmentFailedDescriptionUnknown = "filehubDownloadedAttachmentFailedDescriptionUnknown",
  filehubDownloadedAttachmentFailedDescriptionMaxSize = "filehubDownloadedAttachmentFailedDescriptionMaxSize",
  filehubDownloadedAttachmentsSuccessTitle = "filehubDownloadedAttachmentsSuccessTitle",
  filehubDownloadAttachmentSizeExceededTitle = "filehubDownloadAttachmentSizeExceededTitle",
  filehubDownloadAttachmentSizeExceeded = "filehubDownloadAttachmentSizeExceeded",
  sendingInfo = "sendingInfo",
  sendByEmail = "sendByEmail",
  purchaseInvoiceFormat = "purchaseInvoiceFormat",
  attachingPDFfileInfo="attachingPDFfileInfo",
  publicAttachments="publicAttachments",
  gotoAttachmentsList="gotoAttachmentsList",
  messageText="messageText",
  emailMessage = "emailMessage",
  editEmailMessage = "editEmailMessage",
  messageInfoText = "messageInfoText",
  attachmentsAddedSuccess = "attachmentsAddedSuccess",
  attachmentsAddedError = "attachmentsAddedError",
  filehubStatusTooltipText = "filehubStatusTooltipText",
  requiredEmailField = "requiredEmailField",
  requiredEmailFieldUserName = "requiredEmailFieldUserName",
  requiredEmailFieldMonkeySign = "requiredEmailFieldMonkeySign",
  requiredEmailFieldDotSign = "requiredEmailFieldDotSign",
  requiredEmailFieldDomain = "requiredEmailFieldDomain",
  requiredEmailFieldMainDomain = "requiredEmailFieldMainDomain",
  emailAdres = "emailAdres",
  setEmailAdres = "setEmailAdres",
  loading = "loading",
  filehubMessageSentToReceiver = "filehubMessageSentToReceiver",
  filehubMessageNotSentToReceiver = "filehubMessageNotSentToReceiver",
  cantAddFolder = 'cantAddFolder',
  uploadFoldersProhibited = 'uploadFoldersProhibited',
  send = "send",
  filehubUploadFilesWithInvalidCharacters = 'filehubUploadFilesWithInvalidCharacters',
  filehubCantAddFileWithInvalidCharacters = 'filehubCantAddFileWithInvalidCharacters',
  filehubUploadFilesWithFileSizeExceeded = 'filehubUploadFilesWithFileSizeExceeded',
  filehubCantAddFileWithFileSizeExceeded = 'filehubCantAddFileWithFileSizeExceeded',
  saveEmailMessage = "saveEmailMessage",
  filehubGetAttachmentsErrorTitle = "filehubGetAttachmentsErrorTitle",
  filehubAbandonChanges = "filehubAbandonChanges",
}
